import React from "react";
import { useSelector } from "react-redux";
import { PlainList } from "flatlist-react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBarLeft } from "react-bootstrap-icons";

const MyEntries = () => {
  const params = useParams();
  const Navigate = useNavigate();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Accounts = useSelector((state) => state.account.accounts).filter(
    (account) => parseInt(account.deleted) === 0
  );
  const Modes = useSelector((state) => state.mode.modes).filter(
    (mode) => parseInt(mode.deleted) === 0
  );

  const Entries = useSelector((state) => state.entry.entrys).filter(
    (entry) =>
      parseInt(entry.deleted) === 0 &&
      parseInt(entry.clientLinker) === parseInt(ActiveCred.linker) &&
      parseInt(entry.accountLinker) === parseInt(params.accountLinker)
  );

  const FindMode = (linker) => {
    let mode = Modes.find((mode) => parseInt(mode.linker) === parseInt(linker));

    return mode || { name: "unknown" };
  };

  const Account = () => {
    let account = Accounts.find(
      (account) => parseInt(account.linker) === parseInt(params.accountLinker)
    );

    return account || { name: "unknown" };
  };
  return (
    <div>
      {" "}
      <div className="d-flex justify-content-around">
        <div>
          {" "}
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={() => Navigate(-1)}
          >
            <ArrowBarLeft></ArrowBarLeft> Go Back
          </button>
        </div>
        <div>
          <p className="text-capitalize text-center m-0 p-0 h6">
            {Account().name}
          </p>
        </div>
      </div>
      <table className="table table-sm table-bordered">
        <thead className="thead-dark">
          <tr>
            <th scope="colum">Mode</th>
            <th scope="column">Type</th>
            <th scope="column">Date</th>
            <th scope="column">Details</th>
            <th scope="column">Amount</th>
            <th scope="column">Total</th>
          </tr>
        </thead>
        <tbody>
          <PlainList
            list={Entries.map((entry, index) => {
              const cumulativeTotal = Entries.slice(0, index + 1).reduce(
                (sum, currItem) =>
                  currItem.type === "withdrawal" ||
                  currItem.type === "fine" ||
                  currItem.type === "payment"
                    ? +sum - +currItem.amount
                    : +sum + +currItem.amount,
                0
              );

              return { ...entry, total: cumulativeTotal };
            }).sort((a, b) => parseInt(b.linker) - parseInt(a.linker))}
            renderOnScroll
            renderItem={(entry) => (
              <tr key={entry.linker}>
                <td>{FindMode(entry.modeLinker).name}</td>
                <td>{entry.type}</td>
                <td>
                  {new Date(parseInt(entry.linker)).toLocaleDateString("en-US")}
                </td>
                <td>{entry.details}</td>
                <td>
                  {entry.type === "withdrawal" ||
                  entry.type === "fine" ||
                  entry.type === "payment" ? (
                    <span className="text-primary">- {entry.amount}</span>
                  ) : (
                    <span className="text-success">+ {entry.amount}</span>
                  )}
                </td>
                <td>{entry.total}</td>
              </tr>
            )}
          />
        </tbody>
      </table>
    </div>
  );
};

export default MyEntries;
