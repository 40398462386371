import React, { useState, useEffect } from "react";
import { Envelope, PersonAdd } from "react-bootstrap-icons";
import ClientCrud from "./ClientCrud";
import { useDispatch, useSelector } from "react-redux";
import { FetchClients } from "../../Network/CredApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../Utils/GeneratePdf";
import ClientDisp from "./ClientDisp";
import ClientEntry from "./ClientEntry";
import SingleSms from "../Admin/Sms/SingleSms";
import { useNavigate } from "react-router-dom";
const Clients = () => {
	useEffect(() => {
		FetchClients(ActiveCred.instLinker, User.token, dispatch, online);
	}, []);

	//redux dispatch
	const dispatch = useDispatch();
	const Navigate = useNavigate();
	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const inst = useSelector((state) => state.inst.activeInst);
	const Groups = useSelector((state) => state.group.groups).filter(
		(group) => parseInt(group.deleted) === 0
	);
	const [Group, setGroup] = useState("all");
	const Accounts = useSelector((state) => state.account.accounts).filter(
		(account) => parseInt(account.deleted) === 0
	);
	const Entrys = useSelector((state) => state.entry.entrys).filter(
		(entry) => parseInt(entry.deleted) === 0
	);

	const [Search, setSearch] = useState("");
	const [ShowModal, setShowModal] = useState(false);
	const [SmsModal, setSmsModal] = useState(false);
	const [Client, setClient] = useState({
		firstname: "",
		lastname: "",
		surname: "",
		clientReg: "",
		contact: "",
		email: "",
	});
	const [Account, setAccount] = useState({
		name: "",
		details: "",
	});
	const [Type, setType] = useState("add");
	const [Entry, setEntry] = useState({});
	const [EntryModal, setEntryModal] = useState(false);

	const Clients = useSelector((state) => state.cred.creds)
		.filter(
			(client) =>
				client.credLinker != "0" &&
				parseInt(client.deleted) === 0 &&
				parseInt(client.client) === 1 &&
				(parseInt(client.groupLinker) === parseInt(Group) || Group === "all") &&
				((client.clientReg || "").toLowerCase().search(Search.toLowerCase()) >
					-1 ||
					(client.firstname || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(client.lastname || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(client.surname || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(client.email || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(client.contact || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					Search === "") &&
				parseInt(client.linker) !== parseInt(inst.linker)
		)
		.sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<div className=" d-flex justify-content-around mt-1">
				<p className="text-center h6 text-primary text-capitalize">
					{Clients.length} Clients
				</p>{" "}
				<button
					className="btn btn-sm btn-primary"
					onClick={() => {
						setShowModal(true);
						setType("add");
						setClient({
							firstname: "",
							lastname: "",
							surname: "",
							clientReg: "",
							contact: "",
							email: "",
							status: 0,
							income: "",
							location: "",
							groupLinker: "",
							branchLinker: ActiveCred.branchLinker,
							nokName: "",
							nokContact: "",
							client: 1,
						});
					}}
				>
					<PersonAdd /> Register Client
				</button>{" "}
				{inst.sub === "Kenya" ? (
					<button
						className="btn btn-sm btn-primary"
						onClick={() => {
							Navigate(`/${inst.name}/bulk-sms`);
						}}
					>
						<Envelope /> Sms Clients
					</button>
				) : null}
			</div>
			<table className="table table-sm" id="clients">
				<thead className="thead-dark">
					<tr>
						<th colSpan={2}>
							<input
								type={"text"}
								placeholder={`Search Client`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						{Groups.length > 0 ? <th>Group</th> : null}
						{Accounts.filter((account) => account.status === "saving").map(
							(account) => (
								<th key={account.linker}>{account.name}</th>
							)
						)}
						{Accounts.filter((account) => account.status === "loan").map(
							(account) => (
								<th key={account.linker}>{account.name}</th>
							)
						)}
					</tr>
					<tr>
						<th>ACCNo</th>
						<th>Details</th>
						{Groups.length > 0 ? (
							<th>
								<select
									className="form-control rounded"
									value={Group}
									onChange={(e) => setGroup(e.target.value)}
								>
									<option value={"all"}>All Groups</option>
									{Groups.map((group) => (
										<option key={group.linker} value={group.linker}>
											{group.name}
										</option>
									))}
								</select>
							</th>
						) : null}
						{Accounts.filter((account) => account.status === "saving").map(
							(account) => (
								<th key={account.linker}>
									{Entrys.filter(
										(entry) =>
											parseInt(entry.accountLinker) ===
												parseInt(account.linker) &&
											(entry.type === "saving" ||
												entry.type == "savings-interest") &&
											Clients.some(
												(client) =>
													parseInt(client.linker) ===
													parseInt(entry.clientLinker)
											)
									).reduce((a, b) => +a + +b.amount, 0) -
										Entrys.filter(
											(entry) =>
												parseInt(entry.accountLinker) ===
													parseInt(account.linker) &&
												(entry.type === "withdrawal" || entry.type == "fine") &&
												Clients.some(
													(client) =>
														parseInt(client.linker) ===
														parseInt(entry.clientLinker)
												)
										).reduce((a, b) => +a + +b.amount, 0)}
								</th>
							)
						)}
						{Accounts.filter((account) => account.status === "loan").map(
							(account) => (
								<th key={account.linker}>
									{Entrys.filter(
										(entry) =>
											parseInt(entry.accountLinker) ===
												parseInt(account.linker) &&
											(entry.type === "issued-loan" ||
												entry.type == "loan-interest") &&
											Clients.some(
												(client) =>
													parseInt(client.linker) ===
													parseInt(entry.clientLinker)
											)
									).reduce((a, b) => +a + +b.amount, 0) -
										Entrys.filter(
											(entry) =>
												parseInt(entry.accountLinker) ===
													parseInt(account.linker) &&
												entry.type === "payment" &&
												Clients.some(
													(client) =>
														parseInt(client.linker) ===
														parseInt(entry.clientLinker)
												)
										).reduce((a, b) => +a + +b.amount, 0)}
								</th>
							)
						)}
					</tr>
				</thead>
				<tbody>
					<PlainList
						list={Clients}
						renderOnScroll
						renderItem={(client) => (
							<ClientDisp
								client={client}
								setClient={setClient}
								setType={setType}
								setShowModal={setShowModal}
								setSmsModal={setSmsModal}
								setEntry={setEntry}
								setEntryModal={setEntryModal}
								setAccount={setAccount}
								key={client.linker}
							></ClientDisp>
						)}
					/>
				</tbody>
			</table>
			<ClientCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				client={Client}
				setClient={setClient}
				type={Type}
			></ClientCrud>
			<SingleSms
				Client={Client}
				SmsModal={SmsModal}
				setSmsModal={setSmsModal}
			></SingleSms>
			<ClientEntry
				entry={Entry}
				setEntry={setEntry}
				ShowModal={EntryModal}
				setShowModal={setEntryModal}
				account={Account}
				type={Type}
				client={Client}
			></ClientEntry>
			<div className="d-flex justify-content-center">
				<GeneratePdf id={"clients"} name={`clients`}></GeneratePdf>
			</div>
		</div>
	);
};

export default Clients;
