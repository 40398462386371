import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveAccount, updateLiveAccount } from "../../../Network/AccountApi";

const AccountCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const User = useSelector((state) => state.auth.user);
  const [Loading, setLoading] = useState(false);

  //add account
  const AddAccount = async () => {
    let linker = Date.now();

    await addLiveAccount(
      {
        details: props.account.details,
        linker,
        name: props.account.name,
        type: props.account.type,
        rate: props.account.rate,
        status: props.account.status,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: props.account.status,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );

    props.setAccount({
      ...props.account,
      name: "",
      details: "",
      status: "",
    });
  };

  //edit account
  const EditAccount = async () => {
    let {
      credLinker,

      trace,

      deleted,
    } = props.account;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveAccount(
      { ...props.account, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await AddAccount();
    } else {
      await EditAccount();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">{props.type} Account</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-account">
            <label className="mb-2 text-capitalize"> Account Type:</label>
            <select
              className="rounded form-control"
              placeholder={`enter account name`}
              value={props.account.status}
              onChange={(e) =>
                props.setAccount({
                  ...props.account,
                  status: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            >
              <option value="">Select A/C Type</option>
              <option value={"saving"}>Saving A/C</option>
              <option value={"loan"}>Loan A/C</option>
            </select>
            <hr />
          </div>
          <div className="form-account">
            <label className="mb-2 text-capitalize"> Account Name:</label>
            <input
              className="rounded form-control"
              placeholder={`enter account name`}
              value={props.account.name}
              onChange={(e) =>
                props.setAccount({
                  ...props.account,
                  name: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-account">
            <label className="mb-2 text-capitalize">Account Details:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter account details`}
              value={props.account.details}
              onChange={(e) =>
                props.setAccount({
                  ...props.account,
                  details: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          {props.account.status === "loan" ? (
            <>
              <div className="form-account">
                <label className="mb-2 text-capitalize">Interest Type:</label>
                <select
                  className="rounded form-control"
                  placeholder={`enter account details`}
                  value={props.account.type}
                  onChange={(e) =>
                    props.setAccount({
                      ...props.account,
                      type: e.target.value,
                    })
                  }
                  required
                  readOnly={props.type === "delete" ? true : false}
                >
                  <option value="">Select Interest Type</option>
                  <option value="reducing-balance">Reducing Balance</option>
                  <option value="fixed">Fixed Interest</option>
                </select>
                <hr />
              </div>
              <div className="form-account">
                <label className="mb-2 text-capitalize">
                  Percent Interst Rate P.A: <em>eg 12 for 12% </em>
                </label>
                <input
                  className="rounded form-control"
                  placeholder={`enter percent interest rate p.a`}
                  value={props.account.rate}
                  onChange={(e) =>
                    props.setAccount({
                      ...props.account,
                      rate: e.target.value,
                    })
                  }
                  required
                  readOnly={props.type === "delete" ? true : false}
                  type="number"
                  min={0}
                  max={100}
                />
                <hr />
              </div>
            </>
          ) : null}
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AccountCrud;
