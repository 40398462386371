import React from "react";
import {
	Trash,
	PencilSquare,
	PatchPlus,
	PatchMinus,
	TicketDetailed,
	Envelope,
} from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const ClientDisp = ({
	client,
	setClient,
	setShowModal,
	setType,
	setEntryModal,
	setEntry,
	setAccount,
	setSmsModal,
}) => {
	const Navigate = useNavigate();
	const Groups = useSelector((state) => state.group.groups).filter(
		(group) => parseInt(group.deleted) === 0
	);
	const Branches = useSelector((state) => state.branch.branchs);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const ActiveInst = useSelector((state) => state.inst.activeInst);
	const Creds = useSelector((state) => state.cred.creds);
	const Accounts = useSelector((state) => state.account.accounts).filter(
		(account) => parseInt(account.deleted) === 0
	);
	const Entrys = useSelector((state) => state.entry.entrys).filter(
		(entry) => parseInt(entry.deleted) === 0
	);

	const FindBranch = (branchLinker) => {
		let branch = Branches.find(
			(branch) => parseInt(branch.linker) === parseInt(branchLinker)
		);
		return branch || { name: "none" };
	};

	const FindCred = (credLinker) => {
		let cred = Creds.find(
			(cred) => parseInt(cred.linker) === parseInt(credLinker)
		);
		return cred || { email: "none" };
	};

	const FindGroup = (groupLinker) => {
		let group = Groups.find(
			(group) => parseInt(group.linker) === parseInt(groupLinker)
		);
		return group || { name: "none" };
	};

	return (
		<tr key={client.index}>
			<td> {client.clientReg}</td>
			<td>
				<Dropdown variant="transparent">
					<Dropdown.Toggle variant="transparent">
						{client.firstname}
					</Dropdown.Toggle>
					<Dropdown.Menu variant="transparent">
						<Dropdown.Item>
							<div className="d-flex justify-content-around">
								{ActiveInst.sub === "Kenya" ? (
									<span
										className="text-primary mx-1"
										onClick={() => {
											setSmsModal(true);
											setClient({ ...client });
										}}
										style={{ cursor: "pointer" }}
									>
										<Envelope />
										Sms
									</span>
								) : null}
								<span
									className="text-info mx-1"
									onClick={() => {
										setShowModal(true);
										setType("edit");
										setClient({ ...client });
									}}
									style={{ cursor: "pointer" }}
								>
									<PencilSquare />
									Edit
								</span>

								{parseInt(ActiveCred.admin) === 1 ? (
									<span
										className="text-danger mx-1"
										onClick={() => {
											setShowModal(true);
											setType("delete");
											setClient({ ...client });
										}}
										style={{ cursor: "pointer" }}
									>
										<Trash />
										Delete
									</span>
								) : null}
							</div>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Name : </strong>{" "}
								<em>
									{client.firstname} {client.lastname} {client.surname}
								</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Contact : </strong> <em>{client.contact}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Email : </strong> <em>{client.email}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Next of Kin Name : </strong> <em>{client.nokName}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Next of Kin Contact : </strong>{" "}
								<em>{client.nokContact}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Income : </strong> <em>{client.income}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Location/Residence : </strong>{" "}
								<em>{client.location}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Details : </strong> <em>{client.details}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Registered By : </strong>{" "}
								<em>{FindCred(client.credLinker).email}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Registered Branch : </strong>{" "}
								<em>{FindBranch(client.branchLinker).name}</em>
							</p>
						</Dropdown.Item>
						<Dropdown.Item>
							<p>
								<strong>Registered Date : </strong>{" "}
								<em>
									{new Date(parseInt(client.linker)).toLocaleDateString(
										"en-US"
									)}
								</em>
							</p>
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</td>
			{Groups.length > 0 ? <td>{FindGroup(client.groupLinker).name}</td> : null}
			{Accounts.filter((account) => account.status === "saving").map(
				(account) => (
					<td key={account.linker}>
						<Dropdown variant="transparent">
							<Dropdown.Toggle
								variant="transparent"
								className="text-left m-0 p-0"
							>
								{Entrys.filter(
									(entry) =>
										parseInt(entry.clientLinker) === parseInt(client.linker) &&
										parseInt(entry.accountLinker) ===
											parseInt(account.linker) &&
										(entry.type === "saving" ||
											entry.type == "savings-interest")
								).reduce((a, b) => +a + +b.amount, 0) -
									Entrys.filter(
										(entry) =>
											parseInt(entry.clientLinker) ===
												parseInt(client.linker) &&
											parseInt(entry.accountLinker) ===
												parseInt(account.linker) &&
											(entry.type === "withdrawal" || entry.type == "fine")
									).reduce((a, b) => +a + +b.amount, 0)}
							</Dropdown.Toggle>
							<Dropdown.Menu variant="transparent">
								<Dropdown.Item
									className="text-success mx-1"
									onClick={() => {
										setEntryModal(true);
										setType("add");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
									style={{ cursor: "pointer" }}
								>
									<PatchPlus />
									Add
								</Dropdown.Item>
								<Dropdown.Item
									className="text-danger mx-1"
									onClick={() => {
										setEntryModal(true);
										setType("subtract");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
									style={{ cursor: "pointer" }}
								>
									<PatchMinus />
									Minus
								</Dropdown.Item>
								<Dropdown.Item
									className="text-primary mx-1"
									onClick={() => {
										window.scroll(0, 0);
										Navigate(
											`client/entries/${account.linker}/${client.linker}`
										);
									}}
									style={{ cursor: "pointer" }}
								>
									<TicketDetailed />
									Statement
								</Dropdown.Item>
								<Dropdown.Item
									className="text-primary mx-1"
									onClick={() => {
										window.scroll(0, 0);
										Navigate(
											`client/applications/${account.linker}/${client.linker}`
										);
									}}
									style={{ cursor: "pointer" }}
								>
									<Envelope />
									Applications
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</td>
				)
			)}
			{Accounts.filter((account) => account.status === "loan").map(
				(account) => (
					<td key={account.linker}>
						<Dropdown variant="transparent">
							<Dropdown.Toggle
								className="text-left m-0 p-0"
								variant="transparent"
							>
								{Entrys.filter(
									(entry) =>
										parseInt(entry.clientLinker) === parseInt(client.linker) &&
										parseInt(entry.accountLinker) ===
											parseInt(account.linker) &&
										(entry.type === "issued-loan" ||
											entry.type == "loan-interest")
								).reduce((a, b) => +a + +b.amount, 0) -
									Entrys.filter(
										(entry) =>
											parseInt(entry.clientLinker) ===
												parseInt(client.linker) &&
											parseInt(entry.accountLinker) ===
												parseInt(account.linker) &&
											entry.type === "payment"
									).reduce((a, b) => +a + +b.amount, 0)}
							</Dropdown.Toggle>
							<Dropdown.Menu variant="transparent">
								<Dropdown.Item
									className="text-success mx-1"
									onClick={() => {
										setEntryModal(true);
										setType("add");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
									style={{ cursor: "pointer" }}
								>
									<PatchPlus />
									Add
								</Dropdown.Item>
								<Dropdown.Item
									className="text-danger mx-1"
									onClick={() => {
										setEntryModal(true);
										setType("subtract");
										setClient(client);
										setAccount(account);
										setEntry({
											amount: "",
											details: "",
											security: "",
											guarantors: "",
											principal: "",
											period: "",
											type: "",
											accountLinker: account.linker,
											modeLinker: "",
											clientLinker: client.linker,
											code: "",
										});
									}}
									style={{ cursor: "pointer" }}
								>
									<PatchMinus />
									Minus
								</Dropdown.Item>
								<Dropdown.Item
									className="text-primary mx-1"
									onClick={() => {
										window.scroll(0, 0);
										Navigate(
											`client/entries/${account.linker}/${client.linker}`
										);
									}}
									style={{ cursor: "pointer" }}
								>
									<TicketDetailed />
									Statement
								</Dropdown.Item>
								<Dropdown.Item
									className="text-primary mx-1"
									onClick={() => {
										window.scroll(0, 0);
										Navigate(
											`client/applications/${account.linker}/${client.linker}`
										);
									}}
									style={{ cursor: "pointer" }}
								>
									<Envelope />
									Applications
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</td>
				)
			)}
		</tr>
	);
};

export default ClientDisp;
